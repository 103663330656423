<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Prevalida Details</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div class="col comp-grid" >
                        <div class="">
                            <div >
                                <div class="relative-position" style="min-height:100px">
                                    <template v-if="pageReady">
                                        <div class="grid">
                                            <div class="col-12">
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Recid: </div>
                                                        <div class="font-bold">{{ item.recid }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Trackingnum: </div>
                                                        <div class="font-bold">{{ item.trackingnum }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Carrier: </div>
                                                        <div class="font-bold">{{ item.carrier }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Loadsheet: </div>
                                                        <div class="font-bold">{{ item.loadsheet }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Factura: </div>
                                                        <div class="font-bold">{{ item.factura }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Controlno: </div>
                                                        <div class="font-bold">{{ item.controlno }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Po: </div>
                                                        <div class="font-bold">{{ item.po }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Problempo: </div>
                                                        <div class="font-bold">{{ item.problempo }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Item: </div>
                                                        <div class="font-bold">{{ item.item }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Cantidad: </div>
                                                        <div class="font-bold">{{ item.cantidad }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Unidadmedidacomercial: </div>
                                                        <div class="font-bold">{{ item.unidadmedidacomercial }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Cantidadtarifa: </div>
                                                        <div class="font-bold">{{ item.cantidadtarifa }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Unidadmedidatarifa: </div>
                                                        <div class="font-bold">{{ item.unidadmedidatarifa }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Kgs: </div>
                                                        <div class="font-bold">{{ item.kgs }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Bultos: </div>
                                                        <div class="font-bold">{{ item.bultos }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Modelo: </div>
                                                        <div class="font-bold">{{ item.modelo }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Numparte: </div>
                                                        <div class="font-bold">{{ item.numparte }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Numserie: </div>
                                                        <div class="font-bold">{{ item.numserie }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Mca: </div>
                                                        <div class="font-bold">{{ item.mca }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Descripmex: </div>
                                                        <div class="font-bold">{{ item.descripmex }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Descripusa: </div>
                                                        <div class="font-bold">{{ item.descripusa }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Fraccion: </div>
                                                        <div class="font-bold">{{ item.fraccion }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Fraccionus: </div>
                                                        <div class="font-bold">{{ item.fraccionus }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Costo: </div>
                                                        <div class="font-bold">{{ item.costo }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Valorunitario: </div>
                                                        <div class="font-bold">{{ item.valorunitario }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Valordlls: </div>
                                                        <div class="font-bold">{{ item.valordlls }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Va: </div>
                                                        <div class="font-bold">{{ item.va }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Valortotal: </div>
                                                        <div class="font-bold">{{ item.valortotal }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Origin: </div>
                                                        <div class="font-bold">{{ item.origin }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Notas: </div>
                                                        <div class="font-bold">{{ item.notas }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Whomake: </div>
                                                        <div class="font-bold">{{ item.whomake }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Admin: </div>
                                                        <div class="font-bold">{{ item.admin }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Logstamp: </div>
                                                        <div class="font-bold">{{ item.logstamp }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Moddate: </div>
                                                        <div class="font-bold">
                                                            <Chip v-if="item.moddate" :label="$utils.relativeDate(item.moddate)" v-tooltip="{value: $utils.humanDatetime(item.moddate)}" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Reflink: </div>
                                                        <div class="font-bold">{{ item.reflink }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Vendor: </div>
                                                        <div class="font-bold">{{ item.vendor }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Cont: </div>
                                                        <div class="font-bold">{{ item.cont }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Count: </div>
                                                        <div class="font-bold">{{ item.count }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Observaciones: </div>
                                                        <div class="font-bold">{{ item.observaciones }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Inbond: </div>
                                                        <div class="font-bold">{{ item.inbond }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Sloads: </div>
                                                        <div class="font-bold">{{ item.sloads }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Pic: </div>
                                                        <div class="font-bold">{{ item.pic }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Inwarehouse Flag: </div>
                                                        <div class="font-bold">{{ item.inwarehouse_flag }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Vref: </div>
                                                        <div class="font-bold">{{ item.vref }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Vtracking: </div>
                                                        <div class="font-bold">{{ item.vtracking }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Vinvoice: </div>
                                                        <div class="font-bold">{{ item.vinvoice }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Plantid: </div>
                                                        <div class="font-bold">{{ item.plantid }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Customscode: </div>
                                                        <div class="font-bold">{{ item.customscode }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Listo: </div>
                                                        <div class="font-bold">{{ item.listo }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="flex  justify-btween">
                                                    <SplitButton class="p-button p-button-raised p-button-text p-button-sm" :model="getActionMenuModel(item)">
                                                        <i></i>
                                                    </SplitButton>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="loading">
                                        <div class="p-3 text-center">
                                            <ProgressSpinner style="width:50px;height:50px" />
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
	import {   toRefs, onMounted } from 'vue';
	import { utils } from '@/utils';
	import { useApp } from '@/composables/app.js';
	import { useViewPage } from '@/composables/viewpage.js';
	const props = defineProps({
		id: [String, Number],
		primaryKey: {
			type: String,
			default: 'recid',
		},
		pageName: {
			type: String,
			default: 'prevalida',
		},
		routeName: {
			type: String,
			default: 'prevalidaview',
		},
		apiPath: {
			type: String,
			default: 'prevalida/view',
		},
		editButton: {
			type: Boolean,
			default: true,
		},
		deleteButton: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		msgBeforeDelete: {
			type: String,
			default: "Record deleted successfully",
		},
		msgAfterDelete: {
			type: String,
			default: "Record deleted successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		}
	});
	const app = useApp(props);
	const page = useViewPage(props); // page hook
	//page state
	const { 
		item, // current record from store - Object
		loading, // api loading state
		pageReady, // api data loaded successfully
	} = toRefs(page.state);
	//page computed propties
	const {
		apiUrl, // page current api path
		currentRecord, // current page record  - Object
	} = page.computedProps
	//page methods
	const 
	{ 
		load, // load data from api
		deleteItem, // deleted current record
		exportPage, // export page records - args = (exportFormats, apiUrl, pageName)
		moveToNextRecord, // load next record from api
		moveToPreviousRecord // load previous record from api
	} = page.methods
	function getActionMenuModel(data){
		return [
		{
			label: "Edit",
			to: `/prevalida/edit/${data.recid}`,
			icon: "pi pi-pencil"
		},
		{
			label: "Delete",
			command: (event) => { deleteItem(data.recid) },
			icon: "pi pi-minus-circle"
		}
	]
	}
	onMounted(()=>{ 
		const pageTitle = "Prevalida Details";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		load();
	});
</script>
<style scoped>
</style>
